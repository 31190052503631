import type { FetcherWithComponents } from "@remix-run/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const useManagedFetcher = ({
  fetcher,
  toastSuccessMessage = null,
  errorAsToast = true,
}: {
  fetcher: FetcherWithComponents<{
    successMessage?: string;
    globalError?: string | null;
    fieldErrors?: Record<string, string> | null;
    ok?: boolean;
  }>;
  toastSuccessMessage?: string | null;
  errorAsToast?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (fetcher.state === "idle") {
      setIsLoading(false);
      if (fetcher.data?.globalError) {
        setHasError(true);
        if (errorAsToast) {
          toast.error(fetcher.data.globalError);
        }
      } else if (fetcher.data?.fieldErrors) {
        setHasError(true);
        if (errorAsToast) {
          console.warn("Veuillez corriger les erreurs dans le formulaire");
        }
      } else if (fetcher.data?.ok) {
        setHasError(false);
        setIsDone(true);
        toast.success(fetcher.data.successMessage || toastSuccessMessage);
      }
    } else if (fetcher.state === "submitting" || fetcher.state === "loading") {
      setIsDone(false);
      setIsLoading(true);
    }
  }, [fetcher.state, toastSuccessMessage, fetcher.data, errorAsToast]);

  return [isLoading, isDone, hasError];
};
